import React from "react"
import { graphql } from "gatsby"
import AboutUs from "../../components/pages/AboutUs"

const lang = "ca"

const About = ({ data, pageContext }) => {
  const {
    seoDataJson,
    aboutImagesDesktop,
    aboutImagesMobile,
    profileImages,
  } = data
  const seoData = {
    url: seoDataJson.url_ca,
    titleSeo: seoDataJson.title_ca,
    description: seoDataJson.description_ca,
    alternateLanguage: seoDataJson.alternateLanguage_ca,
    alternateUrl: seoDataJson.alternateUrl_ca,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "ABOUT"
  })

  return (
    <AboutUs
      lang={lang}
      aboutImagesDesktop={aboutImagesDesktop.nodes.map(
        node => node.childImageSharp
      )}
      aboutImagesMobile={aboutImagesMobile.nodes.map(
        node => node.childImageSharp
      )}
      profileImages={profileImages}
      seoData={seoData}
      urls={resultUrls}
    />
  )
}

export const pageQuery = graphql`
  query aboutCaQuery {
    aboutImagesDesktop: allFile(
      filter: {
        relativePath: { glob: "group/about_us/desktop/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }

    aboutImagesMobile: allFile(
      filter: {
        relativePath: { glob: "group/about_us/mobile/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seoDataJson(name: { eq: "about" }) {
      url_ca
      title_ca
      description_ca
      alternateLanguage_ca
      alternateUrl_ca
    }
    profileImages: allFile(
      filter: { relativePath: { glob: "profiles/*" }, extension: { eq: "jpg" } }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default About
